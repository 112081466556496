/*
1  - 13569874
1  - 14069874
===
2  - 15069874		13
2  - 28069874
===
3  - 29069874		13
3  - 42301822
===
4  - 43301822		28
4  - 71311822
===
5  - 72501822		28
5  - 100501822
===
6  - 101101822		5
6  - 106101822
===
7  - 107101822		3
7  - 111001822
===
8  - 111857605		4
8  - 116001822
===
9  - 117001822		14
9  - 131001822
===
10 - 132001822
10 - 146254210
*/

export const composeImageUrl = (nmId) => {
  let vol = parseInt(Number(nmId)/100000);
  let part = parseInt(Number(nmId)/1000);
  // return `https://basket-${_zeroPad(getBasket(nmId))}.wb.ru/${vol}/${part}/${nmId}/images/c246x328/1.jpg`;
  return `https://basket-${_zeroPad(getBasket(vol))}.wbbasket.ru/vol${vol}/part${part}/${nmId}/images/c246x328/1.webp`;
  //https://basket-04.wb.ru/vol593/part59342/59342093/images/c246x328/1.jpg
}

const getBasket = (vol) => {
  switch (true){
    case vol >= 0 && vol <= 143:
      return 1;
    case vol >= 144 && vol <= 287:
      return 2;
    case vol >= 288 && vol <= 431:
      return 3;
    case vol >= 432 && vol <= 719:
      return 4;
    case vol >= 720 && vol <= 1007:
      return 5;
    case vol >= 1008 && vol <= 1061:
      return 6;
    case vol >= 1062 && vol <= 1115:
      return 7;
    case vol >= 1116 && vol <= 1169:
      return 8;
    case vol >= 1170 && vol <= 1313:
      return 9;
    case vol >= 1314 && vol <= 1601:
      return 10;
    case vol >= 1602 && vol <= 1655:
      return 11;
    case vol >= 1656 && vol <= 1919:
      return 12;
    case vol >= 1920 && vol <= 2045:
      return 13;
    case vol >= 2046 && vol <= 2189: // 143
      return 14;
    case vol >= 2190 && vol <= 2405: // 215
      return 15;
    case vol >= 2406 && vol <= 2621:
      return 16;
    case vol >= 2622 && vol <= 2837:
      return 17;
  }
  return 18;
}
const _zeroPad = (num) => {
  return num < 10 ? '0' + num : num;
}

/*
const getBasket = (nmId) => {
  nmId = parseInt(nmId);
  switch (true){
    case nmId > 15069874 && nmId < 28069874:
      return 2;
    case nmId > 29069874 && nmId < 42301822:
      return 3;
    case nmId > 43301822 && nmId < 71311822:
      return 4;
    case nmId > 72501822 && nmId < 100501822:
      return 5;
    case nmId > 101101822 && nmId < 106101822:
      return 6;
    case nmId > 107101822 && nmId < 111001822:
      return 7;
    case nmId > 111757605 && nmId < 116812900:
      return 8;
    case nmId > 117001822 && nmId < 131001822:
      return 9;
    case nmId > 132001822 && nmId < 163000929:
      return 10;
    case nmId > 163000930 && nmId < 166000000:
      return 11;
    case nmId > 166000000:
      return 12;
  }
  return 1;
}
*/
